@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Sevillana&display=swap");

.font-poppins {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-family: "Poppins", sans-serif;
  @apply font-extrabold text-3xl md:text-4xl lg:text-5xl xl:text-6xl leading-normal lg:leading-snug xl:leading-snug;
}

h2 {
  font-family: "Poppins", sans-serif;
  @apply font-extrabold text-xl md:text-2xl lg:text-3xl xl:text-4xl leading-normal lg:leading-snug xl:leading-snug;
}
h4 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

h5 {
  font-family: "Sevillana", cursive;
}
h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  @apply text-base;
}
p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.rounded-btn {
  @apply rounded-full border-gray-400;
}

.rounded-btn span {
  @apply font-bold;
}
.text-btn {
  @apply rounded-full;
}

.text-btn span {
  @apply font-bold;
}
